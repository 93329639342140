<template>
    <div>

        <a-card class="para-card" title="营养液" bordered size="small">

            <control-para-item v-for="item in nutritionParas"
                          :option="item"
                          :iotId="iotId"
                          :valueMap="valueMap"
                          :value="getValue(item)"
            ></control-para-item>

        </a-card>

        <a-card class="para-card"  title="箱体空间环境" bordered size="small">

            <control-para-item v-for="item in boxenvParas"
                               :option="item"
                               :iotId="iotId"
                               :valueMap="valueMap"
                               :value="getValue(item)"
            ></control-para-item>

        </a-card>

        <a-card class="para-card"  title="一区功能" bordered size="small">

            <control-para-item v-for="item in plantParas1"
                               :option="item"
                               :iotId="iotId"
                               :valueMap="valueMap"
                               :value="getValue(item)"
            ></control-para-item>

        </a-card>

        <a-card class="para-card"  title="二区功能" bordered size="small">

            <control-para-item v-for="item in plantParas2"
                               :option="item"
                               :iotId="iotId"
                               :valueMap="valueMap"
                               :value="getValue(item)"
            ></control-para-item>

        </a-card>

        <a-card class="para-card"  title="三区功能" bordered size="small">

            <control-para-item v-for="item in plantParas3"
                               :option="item"
                               :iotId="iotId"
                               :valueMap="valueMap"
                               :value="getValue(item)"
            ></control-para-item>

        </a-card>

    </div>
</template>

<script>
    import ParasUtil from "./ParasUtil"
    import ControlParaItem from "./ControlParaItem";
    export default {
        components:{ControlParaItem},
        props:["iotId","valueMap"],
        data(){
            return {
                currentFloor:0,
            }
        },
        computed: {
            nutritionParas(){
                return ParasUtil.nutritionParas()
            },
            boxenvParas(){
                return ParasUtil.boxenvParas()
            },
            lightParas(){
                return ParasUtil.lightParas(this.currentFloor)
            },
            plantParas(){
                return ParasUtil.plantParas(this.currentFloor)
            },

            plantParas1(){
                return ParasUtil.plantParas(0)
            },
            plantParas2(){
                return ParasUtil.plantParas(1)
            },
            plantParas3(){
                return ParasUtil.plantParas(2)
            },
        },
        methods:{
            getValue(item){
                let value = this.valueMap[item.index+""] || 0
                let switchValue = this.valueMap[item.switchIndex+""] || 0

                if(item.valueFormatter){
                    return item.valueFormatter(switchValue,value)
                }else if(item.rate){
                    if(item.rate<100){
                        return (value/item.rate).toFixed(1)
                    }else{
                        return (value/item.rate).toFixed(2)
                    }
                }else{
                    return value
                }

            }
        }
    }
</script>

<style scoped lang="scss">
.para-card{
    margin-bottom: 20px;
    background: #F8F8F8;
    border-radius: 5px;
}
</style>
